import React from 'react';
import Navbar from './components/Navbar';
import Gallery from './components/Gallery';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import './App.css';

function App() {
  return (
    <>
      <Router>
        <Navbar>
          <Switch>
            <Route path='/' exact />
          </Switch>
        </Navbar>
      </Router>
      <Gallery/>
    </>
  );
}

export default App;
