import React, {useState} from 'react';
import './Gallery.css';

const Gallery = () => {
    let data = new Array(15);
    for(let i=1; i<16; i++){
    data[i] =
        {
            id: i,
            imgSrc: 'https://exemplarsolutions.ca/images/LTE_FrostWeek ('+i+' of 15).jpg '
        }
    }
    const [model, setModel] = useState(false);
    const [tempimgSrc, setTempImgSrc] = useState('');

    const getImg = (imgSrc) =>{
        setTempImgSrc(imgSrc);
        setModel(true);
    }
     return(
         <>
         <div className={model? "model open" : "model"}>
             <img src={tempimgSrc} />
             <div className='closeIcon'>
             <i className="fas fa-times" onClick={() => setModel(false)}/>
             </div>
         </div>
        <div className="gallery">
            {data.map((item,index)=>{
                return(
                    <div className="pics" key={index} onClick={()=> getImg(item.imgSrc)}>
                        <img src={item.imgSrc} style={{width:'100%'}} alt='img'/>
                    </div>
                )
            })}
        </div>
        </>  
     )
 }
 
 export default Gallery